function randomsort() {
  return Math.random() > 0.5 ? -1 : 1;
}

function shuffle(str: string) {
  var randomStr = str.split(",").sort(randomsort);
  return randomStr.join(",");
}

// Max Keys of 576
function generateKeys() {
  const initKeyArr = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z";
  const keyArrX = shuffle(initKeyArr).split(",");
  const keyArrY = shuffle(initKeyArr).split(",");

  const keys = [];
  for (var x = 0; x < keyArrX.length; x++) {
    for (var y = 0; y < keyArrY.length; y++) {
      keys.push("" + keyArrX[x] + keyArrY[y]);
    }
  }
  return shuffle(initKeyArr).split(",").concat(keys).sort(randomsort);
}
const crlfMatch = /(\r\n)/g;
const textMode = {
  crlf: {
    clearNewLines: /^\r\n/gm,
    split: /\r\n/
  },
  lf: {
    clearNewLines: /^\n/gm,
    split: /\n/
  }
}


export default function obsfucate(code: string) {
  let mode = textMode.lf;
  const matchResults = (code.match(crlfMatch) || []).length;
  if (matchResults > 0) {
    mode = textMode.crlf;
    // console.log("mode - crlf", matchResults);
  } else {
    mode = textMode.lf;
    // console.log("mode - lf", matchResults);
  }

  const keys = generateKeys();
  code = code
    .replace(/(#.*)/gm, "")
    .replace(/(}(?!;|else|.*else))/g, "};")
    .replace(/(\t)/g, "")
    .replace(mode.clearNewLines, "")
    .replace(/( {2,10000})/g, " ");
  let keyIndex = 0;
  const lines = code.split(mode.split);
  let keyMap: {[key:string]: string} = {};
  code = lines
    .map((l) => {
      let m = l.match(/(:local|:foreach) (\w*)/);
      if (m) {
        // console.log("match", m[1]);
        const matchedKey = m[2];
        const key = keys[keyIndex];
        keyIndex++;
        if (keyIndex > keys.length) {
          throw new Error(
            "Over " + keys.length + " variables found, unable to continue"
          );
        }

        keyMap[matchedKey] = key;
        l = l.replace(`${m[1]} ${matchedKey}`, `${m[1]} ${key}`);
      }
      const k = Object.keys(keyMap);
      for (let i = 0; i < k.length; i++) {
        const keyWord = k[i];
        l = l.replace(
          new RegExp(`(\\$${keyWord}\\b)`, "g"),
          `$${keyMap[keyWord]}`
        );
        l = l.replace(
          new RegExp(`:set ${keyWord}\\b`, "g"),
          `:set ${keyMap[keyWord]}`
        );
      }
      return l;
    })
    .join("");
  return code.replace(/; */g, ";");
  
}
