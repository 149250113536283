import * as React from "react"

const AceEditor = React.lazy(async () => {
  const ace = await import("react-ace");
  await import("ace-builds/src-noconflict/theme-github");
  return ace;
});
// import "ace-builds/src-noconflict/mode-java";
// import "ace-builds/src-noconflict/ext-language_tools"

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../style/index.scss";
import obsfucate from "../logic/obsfucate";

import FlexSize from "../utils/flexsize";

// markup
const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  const [code, setCode] = React.useState("");

  function onChange(v: string, opt: any) {
    setCode(v);
  }
  let output = "";
  if(code && code !== "") {
    try {
      output = obsfucate(code);
    } catch(err) {
      
    }
  }

  return (<div className="fh-element fh" style={{height: "100vh", paddingBottom: 15}}>
    <Container className="fh-element fh" >
      <Row className="fh-element" >
        <Col xs={6} className="fh">
          <Row >
            <Col>
              <h2>{"Input"}</h2>
            </Col>
          </Row>
          <Row className="fh-element">
            <Col className="fh">
              <FlexSize>
                {({height, width}: any) => {
                  return (<>
                    {(!isSSR && (
                      <React.Suspense fallback={<div />}>
                        <AceEditor
                          height={height}
                          width={width}
                          theme="github"
                          onChange={onChange}
                          name="mikro-obsfucate"
                          setOptions={{}}
                          value={code}
                        />
                    </React.Suspense>))}
                  </>);
                }}
              </FlexSize>
            </Col>
          </Row>
        </Col>
        <Col className="fh" xs={6}>
          <Row>
            <Col>
              <h2>{"Output"}</h2>
            </Col>
          </Row>
          <Row className="fh-element">
            <Col className="fh">
              <FlexSize>
                {({height, width}: any) => {
                  return (<textarea readOnly className="form-control" value={output} style={{width, height}} />);
                }}
              </FlexSize>
            </Col>
          </Row>
        </Col>
      </Row>
      
    
    </Container>
    </div>);
}




export default IndexPage
