import React, {useEffect, useRef, useState} from "react";
import root from "window-or-global";

export function debounce(func: { (height: any, width: any): void; apply?: any; }, timeout = 300) {
    let timer: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(undefined, args);
      }, timeout);
    };
  }
  
  

export default function FlexSize({children, className, style = {}, resizeToParent = false, ...rest}: any) {
  const [size, setSize] = useState({height: 0, width: 0});
  const ref = useRef(null);
  const handleResize = debounce((height: number, width: number) => {
    if (height !== size.height || width !== size.width) {
      setSize({height, width});
    }
  }, 100);

  useEffect(() => {
    let el: any = ref.current;
    if (resizeToParent) {
      el = (ref.current as any)?.parentElement;
    }

    if (el) {
      let interval = setInterval(() => {
        handleResize(el.clientHeight, el.clientWidth);
      }, 1000);

      if (root.ResizeObserver) {
        var ro = new root.ResizeObserver(entries => {
          for (let entry of entries) {
            const cr = entry.contentRect;
            handleResize(cr.height, cr.width);
          }
        });
        ro.observe(el);
        handleResize(el.clientHeight, el.clientWidth);
        return () => {
          clearInterval(interval);
          ro.disconnect();
        };
      }
    }
    return undefined;
  }, [ref?.current]);
  let s = {...style};
  if (resizeToParent) {
    s.height = size.height;
    s.width = size.width;
  }

  return (
    <div
      ref={ref}
      className={`fh fh-element ${className || ""}`}
      style={s}
      {...rest}>
      {children(size)}
    </div>
  );
}
